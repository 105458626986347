.pricing__page {
  width: 100vw;
  min-height: 100vh;
  padding: 5rem 0;
  background-color: #FFF;
  overflow: hidden;
}
.overlay__county {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(86, 88, 89, 0.961);
}
.country__select {
  padding: 2rem 4rem;
  background-color: rgb(10, 14, 18);
  border-radius: 0.7rem;
  color: #FFFFFF;
}
.country__select select {
  background: rgb(40, 49, 58);
  margin-top: 2rem;
  border-radius: 0.4rem;
  color: #FFFFFF;
}
.price__table--page {
  display: flex;
  flex-direction: row;
  gap: 3rem;
  font-family: var(--price-plan-font);
  width: 100%;
  padding: 8rem 0;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 966px) {
  .price__table--page{
    flex-wrap: wrap;
    min-height: 100vh;
    height: unset;
  }
}
.pricing__head--section {
  display: flex;
  gap: 1rem;
  padding: 8rem 2rem 2rem 2rem;
  align-items: center;
  flex-direction: column;
}
.pricing__head--section h1 {
  text-align: center;
  width: 100%;
  font-size: 3rem;
  color: #FFFFFF;
}

.head__options__price {
  gap: 1rem;
  flex-direction: column;
}


.head__options__price div {
  display: flex;
  border-radius: 1.4rem;
  background-color: rgb(238, 237, 237);
}
.head__options__price h2 {
  font-size: 2.8rem;
  letter-spacing: -1px;
  color: rgb(84, 84, 84);
}
.head__options__price div span {
  border-radius: 1.4rem;
  padding: 1rem 2rem;
  color: rgb(65, 65, 65);
}
.head__options__price div span.chosen {
  border-radius: 1.4rem;
  background-color:  rgba(106, 168, 239);
  color: #FFF;
}
.head__options__price button {
  border-radius: 1.4rem;
  background-color:  rgba(106, 168, 239);
  color: #FFF;
  padding: 1rem 2rem;
  border: none;
  outline: none;
  font-weight: bolder;
  font-size: 18px;
  cursor: pointer;
}


.price__plans {
  display: flex;
  justify-content: space-evenly;
  gap: 1rem;
  overflow: scroll;
  padding: 4rem 0;
}
@media screen and (max-width: 1000px) {
  .price__plans {
    justify-content: unset;
    padding: 2rem;
    width: 100vw;
  }
}
.flex-container {
  box-sizing: border-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.direction__column {
  justify-content: flex-start;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.priceColumn-name {
  margin-bottom: 12px;
}
.price__plan {
  flex: 1;
  max-width: 260px;
  min-width: 260px;
  height: fit-content;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 3rem 2rem;
  background-color: rgb(247, 247, 247);
  border-radius: 1rem;
}
.most__popular {
  background-color: #6b757f4f;
  padding: 5rem 3rem;
  max-width: 330px;
  border-radius: 1.8rem;
  position: relative;
}
.most__popular--text {
  position: absolute;
  top:  1rem;
  left: 4rem;
  background-color: var(--color-blog);
  color: #FFFFFF;
  padding: 0.25rem 0.5rem;
  border-radius: 0.4rem;
}
.price__plan--head-container {
  width: 100%;
}
.price__plan--head {
  margin-bottom: 24px;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  margin-right: 6px;
  -webkit-flex-direction: row-reverse;
}
.price__plan--head span {
  font-size: 20px;
  font-weight: 600;
  color: rgb(77, 75, 75);
}
.price__plan--head p {
  font-size: 14px;
  color: rgb(77, 75, 75);
  opacity: 0.7;
}
.price__label {
  display: flex;
  margin: -2px;
  padding: 0;
}
.price__label h2 {
  font-weight: 700;
  color: rgb(77, 75, 75);
  font-size: 36px;
  letter-spacing: -0.1pt;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  margin-right: 4px;
}
.price__label span {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 13px;
  gap: -1rem;
  color: rgb(77, 75, 75);
  opacity: .7;
}
.price__plan--price {
  width: 100%;
}
.price__plan--price   button {
  background-color:  rgba(106, 168, 239);
  border: 2px solid  rgba(106, 168, 239);
  color: #1a1a1a;
  height: 44px;
  margin-top: 16px;
  border-radius: 22px;
  font-size: 16px;
  width: 100%;
  outline: none;
  font-weight: 500;
  font-family: inherit;
}


.list__column--header {
    margin: 12px;
    font-size: 14px;
    color: #FFFFFF;
    opacity: 0.7;
}

.price__column--feature {
  margin-bottom: 8px;
  align-items: flex-start;
}
.check__container {
  min-width: 28px;
  margin-top: 2px;
  color: #FFFFFF;
  opacity: 0.7;
}
.check__text {
  font-size: 14px;
  letter-spacing: -0.1pt;
  line-height: 1.2;
  margin: 0;
  padding: 0;
  color: #FFFFFF;
  opacity: 0.7;
}



.head__options__price {
  gap: 1rem;
  flex-direction: column;
}

.price__features ul{
  list-style: none;
}
.price__features ul li {
  display: flex;
  gap: 1.2rem;
  align-items: center;
  margin-bottom: 3px;
  color: rgb(77, 75, 75);
}
.price__features ul li span{
  color:  rgba(106, 168, 239);
}
.head__options__price div {
  display: flex;
  border-radius: 1.4rem;
  background-color: rgb(238, 237, 237);
}
.head__options__price h2 {
  font-size: 2.8rem;
  letter-spacing: -1px;
  color: rgb(84, 84, 84);
}
@media screen and (max-width: 1000px) {
  .head__options__price h2 {
    font-size: 1.4rem;
    text-align: center;
  }
}
.head__options__price div span {
  border-radius: 1.4rem;
  padding: 1rem 2rem;
  color: rgb(65, 65, 65);
}
.head__options__price div span.chosen {
  border-radius: 1.4rem;
  background-color:  rgba(106, 168, 239);
  color: #FFF;
}
.head__options__price button {
  border-radius: 1.4rem;
  background-color:  rgba(106, 168, 239);
  color: #FFF;
  padding: 1rem 2rem;
  border: none;
  outline: none;
  font-weight: bolder;
  font-size: 18px;
  cursor: pointer;
}
