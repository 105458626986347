.contact__management--images {
  flex: 1.5;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  width: 100%;
}
.project__management--home-text {
  flex: 1;
}
.contact__management--images  img {
  width: 100%;
  opacity: 0.8;
  box-shadow:
  0 -1.5rem 4.5rem -1.5rem rgba(106, 168, 239, 0.666),
  -1.5rem 0 4.5rem -1.5rem rgba(106, 168, 239, 0.666),
  1.5rem 0 4.5rem -1.5rem rgba(106, 168, 239, 0.666);
  border-top-left-radius: 3rem;
}
.img__one{
  border-bottom-left-radius: 2rem;
}

@media screen and (max-width: 1000px) {
  .contact__image--large img {
    border-top-right-radius: 2rem;
  }
  .img__two{
    border-bottom-right-radius: 2rem;
  }
  .contact__management--images {
    max-width: 80vw;
  }
}

@media screen and (max-width: 750px) {
  .contact__image--large img {
    border-top-right-radius: 2rem;
  }
  .img__two{
    border-bottom-right-radius: 2rem;
  }
  .contact__management--images {
    max-width: 98vw;
  }
}