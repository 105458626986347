
.feature__head__container {
  height: 100%;
  padding: 8rem 3rem;
  border-radius: 4rem;
}
.feature__head {
  height: 80vh;
  position: relative;
  border-radius: 4rem;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  width: 100%;
  background-size: contain;
}
.features__head__standard {
  background: url('../Assets/ProjectSchedulingCal.png');
}




.feature__head__text {
  padding: 3rem;
  color: #FFF;
  z-index: 3;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  border-radius: 4rem;
}
.feature__head__text div {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
  gap: 0.75rem;
  z-index: 1;
}
.feature__head__text img{
  z-index: 1;
  max-height: 80%;
  max-width: 250px;
  filter: drop-shadow(0px 4px 4px rgba(109, 108, 108, 0.85));
}
.incident__hand {
  position: absolute;
  bottom: -1rem;
  height: 30rem;
  max-width: unset !important;
  max-height: unset !important;
  right: -10rem;
  z-index: 0 !important;
  filter: drop-shadow(0px 4px 4px rgba(182, 180, 180, 0.85));

}
.feature__head__text h2 {
  letter-spacing: -4px;
  font-size: 4.2rem;
  color: #FFF;
  margin: 0;
  padding: 0;
  line-height: 3.9rem;
}
.feature__head__text p {
  font-size: 1.2rem;
  letter-spacing: 2px;
  color: #FFF;
}
.feature__head__text a, .stripe__text a, .feature__mobile__right a, .final__cta a, .feature__questions__left a, .head__split__one div:first-child a {
  border-radius: 12px;
  background: rgba(106, 168, 239, 0.666);
  padding: 12px 32px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: -0.03em;
  max-height: 60px;
  cursor: pointer;
  width: max-content;
  min-width: max-content;
  text-decoration: none;
  color: rgb(10, 14, 18);
  border: 2px solid transparent;
}

.feature__head__overlay {
  backdrop-filter: blur(32px);
  -webkit-backdrop-filter: blur(32px);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.feature__head__lap img:nth-child(1){
  z-index: 1;
  position: absolute;
  right: -20px;
  top: 0;
  opacity: 0.9;
}


@media screen and (max-width: 1000px) {
  .feature__head__container {
    padding: 3rem 0.5rem;
  }
  .feature__head__text {
    padding: 3rem 0.5rem ;
  }
  .feature__head__lap img, .feature__head__text img {
    display: none;
  }
  .feature__head__text div h2 {
    font-size: 2.5rem;
    line-height: 2rem;
  }
}