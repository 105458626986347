.legal__page {
  width: 100%;
  min-height: 100vh;
  background-color: rgb(10, 14, 18);
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
}
.legal__content {
  min-height: 100vh;
}
.legal__page h1 {
  margin-top: 8rem;
  margin-bottom: 0.5rem;
  font-size: 3rem;
  letter-spacing: -3px;
}
.legal__page h2 {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  font-size: 2.4rem;
  letter-spacing: -3px;
}
.legal__page p {
  letter-spacing: 1px;

}