.contact-page {
    width: 100%;
    min-height: 100dvh;
    /* background-image: url('../Assets/perosnIpad.png'); */
    padding: 4rem 0;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
  }
  .contact__bg {
    /* backdrop-filter: blur(72px);
    -webkit-backdrop-filter: blur(72px); */
    width: 100%;
    /* height: 100%; */
    /* position: absolute;
    top: 0;
    left: 0; */
    flex-direction: column;
    padding: 4rem;
  }
  .contact__info__sitehub {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 6rem;
    padding: 8rem 0;
    /* margin-top: 5rem; */
  }
 
  .contact__info__sitehub div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.35rem;
    color: rgb(59, 54, 54);
    letter-spacing: 2px;
  }
  .contact__info__sitehub h2 {
    font-size: 1.8rem;
    margin-bottom: 0.75rem;
  }
  .contact__bg p {
    text-align: center;
  }
  .home__contact__text {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    background: linear-gradient(to right, rgba(64, 154, 237) 0%, rgb(161, 205, 245) 60%,  rgb(161, 205, 245)  100%);
    padding: 3rem 1rem;
  }
  .home__contact__text h2 {
    color: rgb(59, 54, 54);
  }
  @media screen and (max-width: 1000px) {
    .contact__info__sitehub,.home__contact__text {
      display: flex;
      flex-direction: column;
    }
    .home__contact__text {
      padding: 2rem 0;
      border-radius: 0.3rem;
    }
  }
  @media screen and (max-width: 1000px) {
    .contact-page {
      width: 100%;
      padding: 4rem;
    }
    .contact__bg {
      padding: 8rem 1rem;
      min-height: 110vh;
    }
  }
  .contact-us-form {
    display: flex;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    padding: 2rem;
    border-radius: 4rem;
    height: 35rem;
    min-width: 35rem;
    gap: 2rem;
    flex: 1;
    background: #FFF;
  }
  .contact__heading {
    color: blue;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    flex: 1;
  }
  .contact__heading h1 {
    font-size: 4rem;
    letter-spacing: -3px;
    line-height: 4rem;
  }

  .contact-home-link {
    position: fixed;
    top: 0.5rem;
    left: 0.5rem;
    cursor: pointer;
    z-index: 5;
    font-size: 1.2rem;
    color: blue;
  }
  @media screen and (max-width: 1000px) {
    .contact-page {
      flex-direction: column;
    }
  }
 
  @media screen and (max-width: 700px) {

    .contact__heading {
      gap: 1rem;
      flex: unset;
      text-align: center;
    }
    .contact-us-form {
        flex-direction: column;
        gap: 0.25rem;
        min-width: 97vw;
        width: 97vw;
        height: 40rem;
        padding: 1rem;
    }
    .contact__heading h1 {
      font-size: 3.5rem;
      letter-spacing: -3px;
      line-height: 3.5rem;
      padding-top: 5rem;
    }
  }