/* Looper styles */
@keyframes slideAnimVertical {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(-100%);
  }
}

.looper {
  width: 100%;
  overflow: hidden;
  height: 80vh;
  position: relative;
}
.looper__bottom {
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
  height: 8rem;
  background: linear-gradient(to top, #FFF 10%, rgba(251, 251, 252, 0) 100%);

}
.looper__innerList {
  display: flex;
  flex-direction: column; /* Change to vertical direction */
  align-items: flex-end; /* Center the content vertically */
  height: fit-content; /* Adjust this based on your content */
}

.looper__innerList[data-animate="true"] .looper__listInstance {
  animation: slideAnimVertical linear infinite; /* Use the vertical animation */
}

.looper__listInstance {
  width: max-content;
  animation: none;
}

.description {
  margin-bottom: 16px;  
  text-align: center;
}
.contentBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  margin-top: 2rem;
  padding: 16px; 
  font-weight: 600;
  text-align: center;
  border-radius: 16px;
}
.contentBlock--one img {
  max-width: 25rem;
  opacity: 0.7;
  border-radius: 0.7rem;
}
.contentBlock--one:nth-child(odd) img{
  max-width: 15rem;
}
.contentBlock--one:last-of-type {
  color: var(--green);
  margin-right: 36px;
}
.contentBlock--two {    
  color: var(--green);  
  font-size: 48px;
  background: rgba(255, 255, 255, 0.05);  
}
@media screen and (max-width: 900px) {
  .contentBlock--one img {
    max-height: 15rem;
    opacity: 0.7;
    border-radius: 0.7rem;
  }
  .looper {
    display: none;
  }
}
