.re__use__button {
  /* backdrop-filter: blur(32px); */
  background: rgb(0, 170, 207);
  border-radius: 12px;
  padding: 12px 16px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: -0.03em;
  max-height: 60px;
  cursor: pointer;
  width: max-content;
  min-width: max-content;
  text-decoration: none;
  color: rgb(10, 14, 18);
  border: 2px solid transparent;
}

.re__use__button:hover{
  background: rgba(35, 56, 61, 0.3);
}
.re__use__button  span button{
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border: none;
  outline: none;
  text-decoration: none;
  width: 48px;
  height: 28px;
  margin-left: 16px;
  background: rgb(0, 170, 207);
}

.re__use__button button{
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border: none;
  outline: none;
  text-decoration: none;
  width: 48px;
  height: 28px;
  margin-left: 16px;
  background: rgb(0, 170, 207);
}

.black__button {
  background: rgb(40, 49, 58);
  color: #FFFFFF;
}