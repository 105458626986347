.achieve__more {
  padding: 8rem;
  width: 100%;
  background: radial-gradient(circle, rgba(106, 168, 239, 0.4) 0%, rgba(106, 168, 239, 0.01) 30%); 
}
.achieve__more--container {
  position: relative;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(43px);
  -webkit-backdrop-filter: blur(43px);
}
.achieve__more--image {
  width: 100%;
  height: 100%;
  border-radius: 3rem;
}
.achieve__more--text div {
  display: flex;
  gap: 1rem;
}
.achieve__more--image img {
  border-radius: 3rem;
  width: 100%;
  height: 100%;
  opacity: 0.3;
}
.achieve__more--text {
  color: #3b3b3b;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.achieve__more--text h2 {
  font-size: 5.5rem;
}
.achieve__more--text h3 {
  max-width: 70%;
}
.cta__text {
  gap: 0.75rem;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1200px) {
  .achieve__more {
    padding: 5rem 3rem;
    height: unset;
  }
  .achieve__more--container {
    position: unset;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 4rem;
  }
  .achieve__more--text h2 {
    font-size: 4rem;
  }
  .achieve__more--text h3 {
    letter-spacing: 1px;
    max-width: 60vw;
  }
  .siteHub__home--links {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }

}
@media screen and (max-width: 900px) {
  .achieve__more--image img {
    border-radius: 3rem;
    width: 100%;
    height: unset;
    opacity: 1;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  .achieve__more--text {
    position: unset;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
  }
  .achieve__more {
    padding: 5rem 3rem;
    margin-bottom: 5rem;
  }
  .achieve__more--text h2 {
    font-size: 2.8rem;
    text-align: center;
  }
  .achieve__more--text h3 {
    letter-spacing: 1px;
    font-size: 1rem;
    max-width: 60vw;
    opacity: 0.7;
  }
  .siteHub__home--links a {
    padding: 0.5rem;
  }
  .contentBlock--one img {
    max-height: 15rem;
    opacity: 1;
    border-radius: 0.7rem;
  }
  .achieve__more--image {
    width: 100%;
    height: 100%;
    border-radius: 3rem;

  }
}
@media screen and (max-width: 900px) {
  .achieve__more {
    padding: 3rem 2rem;
    margin-bottom: 1rem;
  }

  .achieve__more--text h3 {
    letter-spacing: 1px;
    font-size: 1rem;
    max-width: 60vw;
    font-weight: 500
  }
}
@media screen and (max-width:750px) {
  .achieve__more {
    padding: 2rem 1rem;
    margin-bottom: 1rem;
  }

  .achieve__more--text  {
    padding: 1rem;
    max-width: 90vw;
  }
  .achieve__more--text div {
    width: 100%;
    flex-direction: column;
  }
  .achieve__more--text div  a, .project__management--home-text a  {
    font-size: 1.4rem;

    width: 100%;
    margin-left: 0;
    letter-spacing: 2px;
    gap: 1rem;
    border-radius: 12px;
    padding: 12px 16px;
    flex: 1;
  }
  .project__management--home-text a {
    text-align: center;
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .achieve__more--text h3 {
    letter-spacing: 1px;
    font-size: 1rem;
    max-width: 60vw;
    font-weight: 500
  }
}