.save__money__msg {
  margin: 5rem 2rem 1rem 2rem;
}
.save__money__msg h2 {
  font-size: 3.4rem;
  letter-spacing: -3px;
  text-align: center;
}
.save__money__msg span {
  color: rgb(65, 150, 247);
}