.home__page {
  width: 100%;
  min-height: 100vh;
  background-color: #FFFDFA;
  color: #3b3b3b;
}
.home__page a {
  opacity: 0.9;
}
.none__view {
  opacity: 0;
  transition: 3s;
}

.view__page { 
  opacity: 1;
  transition: 3s;
}

.scale-up-center-home {
  -webkit-animation: scale-up-center 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
          animation: scale-up-center 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
0% {
-webkit-transform: scale(0.5);
        transform: scale(0.5);
}
100% {
-webkit-transform: scale(1);
        transform: scale(1);
}
}
@keyframes scale-up-center {
0% {
-webkit-transform: scale(0.5);
        transform: scale(0.5);
}
100% {
-webkit-transform: scale(1);
        transform: scale(1);
}
}