.home__contact {
  height: fit-content !important;
  overflow: hidden;
  margin: 8rem 0;
  padding: 3rem;
  position: relative;
  background-size: cover;
  background: url('../../Assets/craneBackdrop.png');
}
.home__contact__bg {
  /* backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px); */
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.home__contact__text {
  z-index: 3;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  height: 100%;
}
.home__contact__text h2 {
  font-size: 2.2rem;
  letter-spacing: -2px;
  text-align: center;
}
.home__contact__text form {
  backdrop-filter: blur(22px);
  -webkit-backdrop-filter: blur(22px);
  width: 35rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  gap: 1rem;
  border: 3px solid rgba(211, 211, 211, 0.161);
}
.error__alert {
  color: #FFFFFF;
  padding: 2rem 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.25rem;
  width: 20rem;
  z-index: 1000000;
  background-color: rgba(37, 118, 211, 0.782);
}
.error__alert--button {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
}
.error__alert--button button {
  color: #FFFFFF;
  border-radius: 0.4rem;
  background-color: rgb(26, 106, 197);
  padding: 0.25rem 1rem;
  outline: none;
  border: none;
  cursor: pointer;
  transition: var(--speed);
}
.error__alert--button button:hover {
  opacity: 0.7;
}
form button.disabled {
  opacity: 0.5;
}
.form__option {
  display: flex;
  flex-direction: column;
  gap: 0;
}
.form__option p {
  color: #FFF;
}
.form__option input {
  height: 2rem;
  backdrop-filter: blur(22px);
  -webkit-backdrop-filter: blur(22px);
  background-color: transparent;
  outline: none;
  color: #FFF;
  border: none;
  font-size: 18px;
  border: 2px solid rgba(211, 211, 211, 0.161);
  border-radius: 0.2rem;
  padding-left: 0.2rem;
}
.form__option textarea {
  height: 4rem;
  color: #FFF;
  resize: none;
  backdrop-filter: blur(22px);
  -webkit-backdrop-filter: blur(22px);
  background-color: transparent;
  outline: none;
  border: none;
  font-size: 18px;
  border: 2px solid rgba(211, 211, 211, 0.161);
  border-radius: .2rem;
}
.home__contact__text form button {
  background: linear-gradient(to right, rgb(227, 238, 248) 0%, rgb(248, 249, 251) 60%,  rgb(246, 248, 250)  100%);
  border: none;
  padding: 0.55rem 1rem;
  border-radius: 1rem;
  color: rgb(59, 54, 54);
  font-weight: bolder;
  font-size: 1.2rem;
  cursor: pointer;
}
@media screen and (max-width: 36rem) {
  .home__contact__text form {
    width: 98vw;
  }

}