.home__link {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 3rem;
}
.home__link__left {
  flex-direction: column;
  padding: 3rem;
  gap: 2rem;
}
.home__link__left h2 {
  font-size: 3.2rem;
  line-height: 2.6rem;
  letter-spacing: -2px;
}
.home__link__left a {
  font-size: 1.4rem;
  margin-left: 2rem;
  letter-spacing: 2px;
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: rgb(106, 168, 239);
  padding: 0.125rem 0.6rem;
  border-radius: 1rem;
  background: rgb(0, 170, 207);
  border-radius: 12px;
  padding: 12px 16px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: -0.03em;
  max-height: 60px;
  cursor: pointer;
  width: max-content;
  min-width: max-content;
  text-decoration: none;
  color: rgb(10, 14, 18);
  border: 2px solid transparent;
}
.home__link__left a:hover {
  opacity: 0.7;
}

.home__link__right {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
}
.home__link__option {
  padding: 1.5rem 1rem;
  display: grid;
  grid-template-columns: 5rem 1fr;
  align-items: center;
}
.home__link__option {
  border-top: 3px solid rgb(229, 228, 228);
}
.home__link__option:first-child {
  border-top: none !important;
}
@media screen and (max-width: 1000px) {
  .home__link {
    display: grid;
    grid-template-columns: 1fr;
    margin: 2rem 0;
  }
  .home__link__left a {
    margin-left: 0;
  }
  .home__link__left h2 {
    margin-left: 0;
  }
  .home__link__option {
    padding: 1rem;
    display: grid;
    grid-template-columns: 5rem 1fr;
    align-items: center;
  }
}