.intro__section h2{
  font-size: 3rem;
  text-align: center;
  letter-spacing: -2px;
}
.intro__section {
  padding: 4rem 0;
}
.intro__img__cont {
  position: relative;
  width: 100%;
  height: 60vh;
  margin: 2rem 0;
}
.intro__img__cont img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 0.25rem;
}
.intro__img__cont img:first-child {
  width: 45%;
  left: 5rem;
}
.intro__img__cont img:last-child {
  width: 55%;
  right: 5rem;
  box-shadow:
  0 -1.5rem 4.5rem -1.5rem rgba(53, 53, 53, 0.666),
  -1.5rem 0 4.5rem -1.5rem rgba(64, 64, 64, 0.666),
  1.5rem 0 4.5rem -1.5rem rgba(85, 85, 86, 0.666);
}
@media screen and (max-width:1000px) {
  .intro__section {
    padding: 2rem 0.5rem;
  }
  .intro__section h2{
    font-size: 2.1rem;
    line-height: 1.7rem;
  }
  .intro__img__cont {
    display: flex;
    flex-direction: column;
    position: unset;
    gap: 1rem;
  }
  .intro__img__cont img {
    position: unset;
    border-radius: 0.25rem;
    max-width: 100%;
    top: unset;
    transform: unset;
  }
  .intro__img__cont img:first-child {
    width: unset;
  }
  .intro__img__cont img:last-child {
    width: unset;
    box-shadow: unset;
  }
}