.landing__header {
  height: 100vh;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
}
.landing__header__bg {
  position: absolute;
  bottom: 25rem;
  width: 970vw;
  height: 97vw;
  background: linear-gradient(to bottom, rgb(65, 150, 247) 70%, rgba(251, 251, 252, 0.84) 100%);
  transform: rotate(20deg);
}
.landing__header__content {
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  width: 100%;
  z-index: 1;
  overflow: hidden;
  align-items: center;
}
.landing__header__content__left {
  padding: 2rem;
  display: flex;
  flex-direction: column;
}

.landing__header__content__left h2 {
  font-size: 3.2rem;
  line-height: 3.8rem;
  letter-spacing: -2px;
  margin-bottom: 1.5rem;
}
.landing__header__content__left p {
  color: rgb(156, 154, 154);
  letter-spacing: 1.2px;
  font-size: 1.1rem;
  line-height: 1.3rem;
}
.landing__header__content__left button {
  padding: 1rem;
  border-radius: 0.2rem;
  margin-top: 3.25rem;
  background: linear-gradient(to right, rgba(65, 150, 247, 0.578) 0%, rgb(65, 150, 247) 100%);
  outline: none;
  border: none;
  font-size: 1.2rem;
  font-weight: bolder;
  color: #FFF;
  cursor: pointer;
  transition: 2s;
  display: flex;
  gap: 2rem;
}

.landing__header__content__left button:hover {
  background: linear-gradient(to right, rgb(65, 150, 247) 0%, rgba(65, 150, 247, 0.578) 100%);
}
.landing__header__content__left button span {
  color: rgb(65, 150, 247);
  display: none;
}
.landing__header__content__left button:hover .landing__header__content__left button span {
  display: flex;
}



.landing__header__img {
  padding-left: 5rem;
  width: 100%;
  overflow: hidden;
}
.landing__header__content img {
  max-height: 60vh;
  border-radius: 0.2rem;
  margin: 4rem 0;
  box-shadow:
  0 -1.5rem 4.5rem -1.5rem rgba(106, 168, 239, 0.666),
  -1.5rem 0 4.5rem -1.5rem rgba(106, 168, 239, 0.666),
  1.5rem 0 4.5rem -1.5rem rgba(106, 168, 239, 0.666);
}



@media screen and (max-width: 1000px) {
  .landing__header {
    height: unset;
    position: relative;
  }
  .landing__header__bg {
    top: -5rem;
    height: 35rem;
  }
  .landing__header__content {
    display: flex;
    flex-direction: column;
  }
  .landing__header__content__left {
    padding: 0.5rem;
    align-items: center;
    text-align: center;
  }
  .landing__header__content__left h2 {
    font-size: 2.6rem;
    text-align: center;
    line-height: 2.1rem;
    letter-spacing: -2px;
    margin-bottom: 1.5rem;
    margin-top: 8rem;
  }
  .landing__header__img {
    padding: 0.5rem;
  }
  .landing__header__img img {
    max-width: 100%;
  }
}