.free__view {
  height: 35vh;
  position: relative;
  border-radius: 4rem;
  display: flex;
  overflow: hidden;
  width: 100%;
  margin: 3rem 0;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  padding: 3rem;
  text-align: center;
  background-size: cover;
  background: url('../../Assets/projectSiteView.png');
}
.feature__head__overlay {
  backdrop-filter: blur(22px);
  -webkit-backdrop-filter: blur(22px);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.free__view h2{
  z-index: 1;
  font-size: 4rem;
  letter-spacing: -2px;
}
.free__view a {
  letter-spacing: 1.2px;
  font-weight: bolder;
  display: flex;
  align-items: center;
  gap: 1rem;
  flex: 1;
  height: 100%;
  padding: 1rem 2rem;
  border-radius: 1.2rem;
  margin-right: 1rem;
}

@media screen and (max-width: 1000px) {
  .free__view {
    padding: 2rem 1rem;
  }
  .free__view h2{
    z-index: 1;
    font-size: 2rem;
    letter-spacing: -2px;
  }
  .free__view a {
    padding: 0.75rem 0.5rem;
  }
}