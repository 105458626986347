.siteHub__cta--home {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 2rem;
    margin: 4rem; 
    gap: 1rem;
    border-radius: 1rem;
    background: 
    linear-gradient(to right,    rgb(26, 106, 197) -10%,    rgba(106, 168, 239, 0.44) 50%,  transparent 100%),
    linear-gradient(to left, rgb(11, 98, 142) -45%,    rgba(106, 168, 239, 0.44) 85%, transparent 100%);
}

 .siteHub__cta-content p {
     font-family: var(--font-family);
     font-weight: 500;
     font-size: 12px;
     line-height: 30px;
     color: var(--color-footer);
 }
 .siteHub__cta-content h2 {
     font-family: var(--font-family);
     font-weight: 800;
     font-size: 24px;
     line-height: 45px;
     margin: 0.75rem;
     color: var(--color-footer);
 }
 .siteHub__cta-content h4 {
     font-family: var(--font-family);
     font-weight: 800;
     font-size: 4rem;
     line-height: 3.8rem;
     margin: 0.75rem;
     letter-spacing: -5px;
     color: var(--color-footer);
 }
 .siteHub__cta-btn--home {
     display: flex;
     justify-content: center;
     align-items: center;
     margin-left: 2rem;
 }
 .siteHub__cta-btn--home button {
     border-radius: 40px;
     font-family: var(--font-family);
     font-weight: bold;
     font-size:18px;
     line-height: 30px;
     color: #FFF;
     padding: 0.5rem 1rem;
     border: none;
     outline: none;
     cursor: pointer;
     min-width: 150px;
     background-color: rgba(31, 7, 117, 0.313);
 }
 @media screen and (max-width: 650px){
    .siteHub__cta-content h2 {
        font-family: var(--font-family);
        font-weight: 800;
        font-size: 34px;
        line-height: 32px;
        margin: 0.75rem;
        color: var(--color-footer);
    }
     .siteHub__cta--home {
         flex-direction: column;
         text-align: center;
     }
     .siteHub__cta-btn--home {
         margin: 2rem 0 0;
     }
     .siteHub__cta-content h4 {
        font-family: var(--font-family);
        font-weight: 800;
        font-size: 2rem;
        line-height: 1.8rem;
        margin: 0.75rem;
        letter-spacing: -2px;
        color: var(--color-footer);
    }
 }
 @media screen and (max-width: 550px) {
     .siteHub__cta--home {
       margin: 4rem 2rem;  
     }
     .siteHub__cta-content h3 {
         font-size: 18px;
         line-height: 32px;
         
     }
     .siteHub__cta-btn---home button {
         font-size: 14px;
         line-height: 28px;
     }
 }