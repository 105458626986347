.project__management--home {
  display: flex;
  gap: 3rem;
  width: 100%;
  color: #3b3b3b;
  padding: 3rem 0;
  align-items: center;
}
.project__management--home-text {
  flex: 1;
  flex-direction: column;
  padding: 3rem;
  gap: 2rem;
  display: flex;
}
.project__management--home-text h2{
  font-size: 3.3rem;
  line-height: 2.7rem;
  letter-spacing: -2px;
}
.project__management--home-text p {
  letter-spacing: 1px;
  font-size: 15px;
  line-height: 22px;
  opacity: 0.8;
}
.project__management--home-image {
  flex: 1.5;
  width: 100%;
}
.project__management--home-image  img {
  width: 100%;
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
  opacity: 0.8;
  box-shadow:
  0 -1.5rem 4.5rem -1.5rem rgba(106, 168, 239, 0.666),
  -1.5rem 0 4.5rem -1.5rem rgba(106, 168, 239, 0.666),
  1.5rem 0 4.5rem -1.5rem rgba(106, 168, 239, 0.666);
}
@media screen and (max-width: 1200px) {
  .project__management--home {
    gap: 1.5rem;
  }
  .project__management--home-image {
    display: flex;
    align-items: center;
    flex: 1.2;
  }
}
@media screen and (max-width: 1000px) {
  .project__management--home {
    flex-direction: column-reverse;
    height: unset;
  }
  .project__management--home-image {
    width: 100vw;
    justify-content: flex-end;

  }
  .project__management--home-image img {
    max-width: 90vw;
  }
  .project__management--home-text {
    padding: 3rem 0;
    gap: 2rem;
    flex: unset;
    align-items: center;
    display: flex;
  }
  .project__management--home-text h2{
    font-size: 2.2rem;
    letter-spacing: -2.8px;
    text-align: center;
  }
  .project__management--home-text p {
    letter-spacing: 1px;
    padding: 0.75rem;
    font-size: 14px;
    line-height: 20px;
    opacity: 0.8;
  }
}
