
.flex-container {
  box-sizing: border-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.direction__column {
  justify-content: flex-start;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.priceColumn-name {
  margin-bottom: 12px;
}
.price__plan {
  flex: 1;
  max-width: 260px;
  min-width: 260px;
  height: fit-content;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 3rem 2rem;
  background-color: rgb(247, 247, 247);
  border-radius: 1rem;
}
.price__plan--price h4 {
  color: rgba(106, 168, 239);
}
.most__popular {
  background-color: #6b757f4f;
  padding: 5rem 3rem;
  max-width: 330px;
  border-radius: 1.8rem;
  position: relative;
}
.most__popular--text {
  position: absolute;
  top:  1rem;
  left: 4rem;
  background-color: var(--color-blog);
  color: #FFFFFF;
  padding: 0.25rem 0.5rem;
  border-radius: 0.4rem;
}
.price__plan--head-container {
  width: 100%;
}
.price__plan--head {
  margin-bottom: 24px;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  margin-right: 6px;
  -webkit-flex-direction: row-reverse;
}
.price__plan--head span {
  font-size: 20px;
  font-weight: 600;
  color: rgb(77, 75, 75);
}
.price__plan--head p {
  font-size: 14px;
  color: rgb(77, 75, 75);
  opacity: 0.7;
}
.price__label {
  display: flex;
  margin: -2px;
  padding: 0;
}
.price__label h2 {
  font-weight: 700;
  color: rgb(77, 75, 75);
  font-size: 36px;
  letter-spacing: -0.1pt;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  margin-right: 4px;
}
.price__label span {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 13px;
  gap: -1rem;
  color: rgb(77, 75, 75);
  opacity: .7;
}
.price__plan--price {
  width: 100%;
}
.price__plan   button {
  background-color:  rgba(106, 168, 239);
  border: 2px solid  rgba(106, 168, 239);
  color: #FFF;
  height: 44px;
  margin-bottom: 1rem;
  border-radius: 22px;
  font-size: 16px;
  width: 100%;
  outline: none;
  font-weight: 500;
  font-family: inherit;
}


.price__features ul{
  list-style: none;
}
.price__features ul li {
  display: flex;
  gap: 1.2rem;
  align-items: center;
  margin-bottom: 3px;
  color: rgb(77, 75, 75);
}
.price__features ul li span{
  color:  rgba(106, 168, 239);
}



.list__column--header {
    margin: 12px;
    font-size: 14px;
    color: #FFFFFF;
    opacity: 0.7;
}

.price__column--feature {
  margin-bottom: 8px;
  align-items: flex-start;
}
.check__container {
  min-width: 28px;
  margin-top: 2px;
  color: #FFFFFF;
  opacity: 0.7;
}
.check__text {
  font-size: 14px;
  letter-spacing: -0.1pt;
  line-height: 1.2;
  margin: 0;
  padding: 0;
  color: #FFFFFF;
  opacity: 0.7;
}