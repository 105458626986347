* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

a {
  color: unset;
  text-decoration: none;
}
  .section__padding {
      padding: 4rem 6rem;
  }

  .section__margin {
      padding: 4rem 6rem;
  }

  .scale-up-center {
      -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
              animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.view__video {
  width: 100vw;
}
.view__video video {
  width: 100%;
}

@media screen and (max-width: 700px) {
  .section__padding {
      padding: 4rem;
  }
  .section__margin {
      padding: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
      padding: 4rem 2rem;
  }
  .section__margin {
      padding: 4rem 2rem;
  }
}